export const projectDescription = ''

export const initialSituation = ''

export const nineBack = ''

export const nineNow = ''

export const nineFuture = ''

export const evaluationDetail = ''

export const technicalSolution = ''

export const physicalConflict = ''

export const finalConfirm = ''

export const achievement = ''

export const lineShapes = {
	bad: 10,
	normal: 11,
	unsatisfy: 12,
	over: 13,
}

export const lineShapesText = {
	10: '有害',
	11: '正常',
	12: '不足',
	13: '过量',
}

export const lineShapeList = [
	1, // 直线
	// 4, // 折线
	// 5, // 曲线
	41, // 折线-v
	42, // 折线-h
]

export const defaultTriz = {
	// 当前Triz内容
	system: '',
	projectDescription,
	initialSituation,
	roadmapList: [],
	backgroud: {
		init: '',
		current: '',
		target: '',
		business: '',
		calculate: '',
		limit: '',
		allow: '',
	},
	selectedRecognizeSteps: [],
	selectedResolveSteps: [],
	nineScreenGraph: {
		sup: {
			back: '',
			now: '',
			future: '',
		},
		own: {
			back: '',
			now: '',
			future: '',
		},
		sub: {
			back: '',
			now: '',
			future: '',
		},
	},
	nineScreenProblem: {
		back: nineBack,
		now: nineNow,
		future: nineFuture,
	},
	nineScreenSlots: {
		slot01: '',
		slot02: '',
		slot03: '',
		slot04: '',
	},
	nineResolves: [],
	curve: {
		slot01: '',
		slot02: '',
		slot03: '',
		slot04: '',
	},
	evaluation: {
		selectedEvaluations: [],
		problems: {
			problem1: '',
			problem2: '',
			problem3: '',
			problem4: '',
			problem5: '',
			problem6: '',
			problem7: '',
			problem8: '',
		},
		slots: {
			slot01: '',
			slot02: '',
			slot03: '',
			slot04: '',
			slot05: '',
			slot06: '',
			slot07: '',
			slot08: '',
			slot09: '',
			slot10: '',
			slot11: '',
			slot12: '',
			slot13: '',
			slot14: '',
			slot15: '',
			slot16: '',
			slot17: '',
			slot18: '',
			slot19: '',
			slot20: '',
			slot21: '',
		},
		results: [{
			target: '',
			type: null,
			detail: evaluationDetail,
		}, ],
	},
	reasonTreeData: {
		id: 1,
		label: '初始缺点',
		logic: true,
	},
	reasons: [],
	reasonSlots: {
		slot01: '',
		slot02: '',
		slot03: '',
	},
	functionSlots: {
		slot01: '',
		slot02: '',
		slot03: '',
	},
	issues: [],
	relationGraphData: {
		rootId: `root-${new Date().getTime()}`,
		nodes: [{
			id: `root-${new Date().getTime()}`,
			text: '',
			data: {
				comp: true
			},
		}, ],
		links: [],
	},
	clips: [],
	clipResolves: [],
	relations: [
		[0, 0],
		[0, 0],
	],
	functions: [],
	resources: [],
	resourcesAnalyze: [{
			type: '物质资源',
			child: '',
			system: '',
			super: ''
		},
		{
			type: '能量资源',
			child: '',
			system: '',
			super: ''
		},
		{
			type: '空间资源',
			child: '',
			system: '',
			super: ''
		},
		{
			type: '时间资源',
			child: '',
			system: '',
			super: ''
		},
		{
			type: '信息资源',
			child: '',
			system: '',
			super: ''
		},
		{
			type: '功能资源',
			child: '',
			system: '',
			super: ''
		},
	],

	idealResolveAnalyze: [{
			question: '设计最终目标？',
			answer: ''
		},
		{
			question: '理想化最终结果？',
			answer: ''
		},
		{
			question: '达到理想解的障碍是什么？',
			answer: ''
		},
		{
			question: '出现这种障碍的结果是什么？',
			answer: ''
		},
		{
			question: '不出现这种障碍的条件是什么？',
			answer: ''
		},
		{
			question: '创造这些条件所用的资源是什么？',
			answer: ''
		},
	],
	idealResolveSlots: {
		slot01: '',
		slot02: '',
		slot03: '',
	},
	idealResolves: [],
	keyProblems: [],
	searches: [],
	standardResolves: [],
	solutionScores: [],
	finalConfirm,
	achievement,
}

export const functionTypes = [{
		text: '有用功能',
		value: '有用功能'
	},
	{
		text: '有害功能',
		value: '有害功能'
	},
]

export const priceTypes = [{
		text: '昂贵',
		value: '昂贵'
	},
	{
		text: '廉价',
		value: '廉价'
	},
	{
		text: '免费',
		value: '免费'
	},
]

export const qauntityTypes = [{
		text: '不足',
		value: '不足'
	},
	{
		text: '足够',
		value: '足够'
	},
]

export const excutionTypes = [{
		text: '正常',
		value: lineShapes.normal
	},
	{
		text: '过度',
		value: lineShapes.over
	},
	{
		text: '不足',
		value: lineShapes.unsatisfy
	},
	// { text: '有害', value: lineShapes.bad },
]

export const qualityTypes = [{
		text: '有用',
		value: '有用'
	},
	// { text: '中性', value: '中性' },
	{
		text: '有害',
		value: '有害'
	},
]

export const avilabilityTypes = [{
		text: '需要建造',
		value: '需要建造'
	},
	{
		text: '成品',
		value: '成品'
	},
	{
		text: '组装后可用',
		value: '组装后可用'
	},
]

export const functionScores = [{
		text: '基本功能',
		value: '基本功能',
		score: 3
	},
	{
		text: '附加功能',
		value: '附加功能',
		score: 2
	},
	{
		text: '辅助功能',
		value: '辅助功能',
		score: 1
	},
	// { text: '有害功能', value: '有害功能', score: 0 },
]

export const methods = [{
		text: '分离矛盾',
		value: '分离矛盾'
	},
	{
		text: '满足矛盾',
		value: '满足矛盾',
		suggestion: [13, 36, 37, 28, 35, 38, 39]
	},
	{
		text: '绕过矛盾',
		value: '绕过矛盾',
		suggestion: [6, 13, 25]
	},
]

export const keyWords = [{
		text: '在哪里',
		value: '在哪里',
		resolve: '空间分离',
		suggestion: [1, 2, 3, 7, 4, 17],
	},
	{
		text: '在什么时候',
		value: '在什么时候',
		resolve: '时间分离',
		suggestion: [9, 10, 11, 15, 34],
	},
	{
		text: '对谁',
		value: '对谁',
		resolve: '关系分离',
		suggestion: [3, 17, 19, 31, 32, 40],
	},
	{
		text: '在什么方向上',
		value: '在什么方向上',
		resolve: '方向分离',
		suggestion: [4, 40, 35, 14, 17, 32, 7],
	},
	{
		text: '其他',
		value: '其他',
		resolve: '系统级别的分离',
		suggestion: [1, 5, 12, 33],
	},
]

export const piriod = [{
		text: '成熟期',
		value: '成熟期'
	},
	{
		text: '衰退期',
		value: '衰退期'
	},
]

export const evaluations = [{
		text: '提高理想度法则',
		value: 1
	},
	{
		text: '子系统不均衡进化法则',
		value: 2
	},
	{
		text: '动态性进化法则',
		value: 3
	},
	{
		text: '子系统协调性进化法则',
		value: 4
	},
	{
		text: '向微观级和场的应用进化法则',
		value: 5
	},
	{
		text: '增加集成度再进行简化的进化法则',
		value: 6
	},
	{
		text: '能量传递法则',
		value: 7
	},
	{
		text: '完备性进化法则',
		value: 8
	},
]

export const clipRules = [{
		text: '裁剪规则A',
		value: '裁剪规则A'
	},
	{
		text: '裁剪规则B',
		value: '裁剪规则B'
	},
	{
		text: '裁剪规则C',
		value: '裁剪规则C'
	},
]

export const variants = [{
		title: "汇总问题工具",
		val: [{
			path: 'functional',
			name: "功能分析",
			caption: '功能分析在TRIZ理论中是一个重要的问题识别工具。它的主要目的是识别系统中存在问题的功能，即确定哪些功能无法满足要求或产生了不良效果。通过功能分析，可以帮助发现系统中的矛盾、冲突或不完善之处，从而为创新和改进提供方向。'
		}, {
			path: 'CausalChain',
			name: "因果链分析",
			caption: '因果链分析基于因果关系的思维方式，通过追溯问题的发生和影响，找到导致问题的根本原因，并进一步分析这些原因之间的因果链条。这有助于揭示隐藏的矛盾和冲突，并为创新和改进提供启示'
		}, {
			path: 'FunctionalCropping',
			name: "功能裁剪",
			caption: '既是分析问题工具也是解决问题的工具。功能裁剪工具是一种用于解决问题和创新的方法。它的主要目的是通过削减或去除系统或产品中的某些功能，来解决矛盾和问题。于解决复杂系统和产品中的问题，并促进创新和改进。它可以帮助找到系统中不必要或冲突的功能，并提供简化和优化系统的路径'
		}],
	},
	{
		title: "解决问题工具",
		val: [{
			path: 'resolvingConflicts',
			name: "技术与物理矛盾",
			caption: '通过技术矛盾，改善一个参数恶化了另一个参数（两个参数），查询阿奇舒勒矛盾矩阵，找到发明原理；物理矛盾（同一个参数）查找发明原理。通过发明原理启发创新思考，解决创新过程中的问题。是TRIZ理论中尤为重要的工具'
		}, {
			path: 'fos',
			name: "功能导向搜索（FOS）",
			caption: '功能导向搜索是TRIZ中的一种创新方法，通过从功能的角度思考和改变，帮助发现新的解决方案和创新的可能性。它能够突破传统思维的限制，促进创新和问题解决的过程。'
		}, {
			path: 'StandardSolution',
			name: "标准解",
			caption: '是指一系列经过验证和广泛应用的通用解决方案，用于解决特定类型的技术矛盾或问题。这些标准解是基于TRIZ的研究和分析，总结出的成功解决问题的模式和原则'
		}, {
			path: 'science',
			name: "科学效应库",
			caption: '科学效应库是TRIZ的核心知识库之一，它包含了各种已知的科学效应，这些效应是在不同领域和行业中被发现和验证的。科学效应库提供了一种系统的方法，帮助工程师和创新者在面对技术矛盾时，通过借鉴和应用已有的科学效应，找到解决问题的线索和思路'
		}, {
			path: 'inventive',
			name: "发明原理",
			caption: '“40项发明原理”是 TRIZ 中重要组成部分之一, 这些通用性强且实际效果良好的设计规律能够指导人们从各个角度去审视问题所在，并为我们寻求合适而有效率的改进办法打开更广阔空间。'
		}],
	},{
		title: "其他",
		val: [{
			path: 'patent',
			name: "专利查询",
			caption: '免费查询专利，一键生成专利分析报告'
		},],
	},
]

export const menu_left = {
	// "functional":[{name:"组件",icon:"mdi-tools",component:"FunctionalComponent"},{name:"相互作用",icon:"mdi-tools",component:"FunctionalEffect"},{name:"建模",icon:"mdi-tools",component:"FunctionalModel"},{name:"模型图",icon:"mdi-tools",component:"FunctionalModelDiagram"},{name:"我的",icon:"mdi-account-outline",component:"MyList"}],
	"functional": [{
		name: "我的",
		icon: "mdi-account-outline",
		component: "MyList"
	}],
	"CausalChain": [
		// 	{
		// 	name: "解决方案",
		// 	icon: "mdi-tools",
		// 	component: "ReasonsResolves"
		// },
		{
			name: "我的",
			icon: "mdi-account-outline",
			component: "MyList"
		}
	],
	"fos": [{
		name: "我的",
		icon: "mdi-account-outline",
		component: "MyList"
	}],
	"FunctionalCropping": [
		// 	{
		// 	name: "裁剪",
		// 	icon: "mdi-tools",
		// 	component: "Clip"
		// },{
		// 	name: "解决方案",
		// 	icon: "mdi-tools",
		// 	component: "ReasonsResolves"
		// },
		{
			name: "我的",
			icon: "mdi-account-outline",
			component: "MyList"
		}
	],
	"resolvingConflicts": [{
		name: "我的",
		icon: "mdi-account-outline",
		component: "MyList"
	}],
	"StandardSolution": [{
		name: "我的",
		icon: "mdi-account-outline",
		component: "MyList"
	}],
	"inventive": [{
		name: "我的",
		icon: "mdi-account-outline",
		component: "MyList"
	}],
	"science": [{
		name: "功能",
		icon: "mdi-alpha-f-circle-outline",
		component: 0
	},{
		name: "参数",
		icon: "mdi-alpha-p-circle-outline",
		component: 1
	},{
		name: "变化",
		icon: "mdi-alpha-t-circle-outline",
		component: 2
	}],
}
export const triz_toole_list = {
	"functional": [{
			name: '组件分析',
			component: 'FunctionalComponent',
		},
		{
			name: '相互作用分析',
			component: 'FunctionalEffect',
		},
		{
			name: '功能建模',
			component: 'FunctionalModel',
		},
		{
			name: '功能模型图',
			component: 'FunctionalModelDiagram',
		},
	],
	"CausalChain": [{
			name: '因果链关系图',
			component: 'Reason',
		},
		{
			name: '关键问题转化',
			component: 'Reason_tab',
		}
	],
	"fos": [{
		name: '功能导向搜索（FOS）',
		component: 'GuideSearch',
	}, ],
	"FunctionalCropping": [{
			name: '组件分析',
			component: 'FunctionalComponent',
		},
		{
			name: '相互作用分析',
			component: 'FunctionalEffect',
		},
		{
			name: '功能建模',
			component: 'FunctionalModel',
		},
		{
			name: '功能模型图',
			component: 'FunctionalModelDiagram',
		}, {
			name: '功能裁剪',
			component: 'Clip',
		},
	],
	"resolvingConflicts": [{
		name: '解决矛盾的创新原理或技术',
		component: 'KeyProblem',
	}, ],
	"science": [{
		name: '科学效应库',
		component: 'StandardResovle',
	}, ],
	"StandardSolution": [{
		name: '标准解',
		component: 'StandardResovle',
	}, ]
}