export const parameters = [
  { value: 1, text: '运动物体的重量' ,tooltip:'运动物体的重量是指在重力场中运动物体多受到的重力。如运动物体作用于其支撑或悬挂装置上的力。(运动物体是指自身或借助于外力可在一定的空间内运动的物体)'},
  { value: 2, text: '静止物体的重量' ,tooltip:'静止物体的重量是指在重力场中静止物体所受到的重力。如静止物体作用于其支撑或悬挂装置上的力。(静止物体是指自身或借助于外力都不能使其在空间内运动的物体)'},
  { value: 3, text: '运动物体的长度' ,tooltip:'运动物体的长度是指运动物体的任意线性尺寸,不一定是最长的,都认为是其长度。'},
  { value: 4, text: '静止物体的长度' ,tooltip:'静止物体的长度是指静止物体的任意线性尺寸,不一定是最长的,都认为是其长度。'},
  { value: 5, text: '运动物体的面积' ,tooltip:'运动物体的面积是指运动物体内部或外部所具有的表面或部分表面的面积。 '},
  { value: 6, text: '静止物体的面积',tooltip:'静止物体的面积是指静止物体内部或外部所具有的表面或部分表面的面积。' },
  { value: 7, text: '运动物体的体积',tooltip:'运动物体的体积是指运动物体所占有的空间体积。' },
  { value: 8, text: '静止物体的体积' ,tooltip:'静止物体的体积是指静止物体所占有的空间体积。'},
  { value: 9, text: '速度' ,tooltip:'速度是指物体的运动速度、过程或活动与时间之比。'},
  { value: 10, text: '力',tooltip:'力是指两个系统之间的相互作用。对于牛顿力学,力等于质量与加速度之积。在TRIZ中,力是试图改变物体状态的任何作用。' },
  { value: 11, text: '应力/压强' ,tooltip:'应力或压力是指单位面积上的力。'},
  { value: 12, text: '形状',tooltip:'形状是指物体外部轮廓或系统的外貌。' },
  { value: 13, text: '稳定性' ,tooltip:'结构的稳定性是指系统的完整性及系统组成部分之间的关系。磨损、化学分解及拆卸都降低稳定性。'},
  { value: 14, text: '强度' ,tooltip:'强度是指物体抵抗外力作用使之变化的能力。'},
  { value: 15, text: '运动物体的作用时间',tooltip:'运动物体作用时间是指物体完成规定动作的时间、服务期。两次误动作之间的时间也是作用时间的一种度量。' },
  { value: 16, text: '静止物体的作用时间',tooltip:'静止物体作用时间是指物体完成规定动作的时间、服务期。两次误动作之间的时间也是作用时间的一种度量。' },
  { value: 17, text: '温度' ,tooltip:'温度是指物体或系统所处的热状态,包括其他热参数,如影响改变温度变化速度的热容量。'},
  { value: 18, text: '照度' ,tooltip:'光照度是指单位面积上的光通量,系统的光照特性,如亮度、光线质量。'},
  { value: 19, text: '运动物体的能量消耗',tooltip:'运动物体的能量是指能量是物体做功的一种度量。在经典力学中,能量等于力与距离的乘积。能量也包括电能、热能及核能等。' },
  { value: 20, text: '静止物体的能量消耗' ,tooltip:'静止物体的能量是指能量是物体做功的一种度量。在经典力学中,能量等于力与距离的乘积。能量也包括电能、热能及核能等。'},
  { value: 21, text: '功率',tooltip:'功率是指单位时间内所做的功,即利用能量的速度。' },
  { value: 22, text: '能量损失',tooltip:'能量损失是指为了减少能量损失,需要不同的技术来改善能量的利用。' },
  { value: 23, text: '物质损失',tooltip:'物质损失是指部分或全部、永久或临时的材料、部件或子系统等物质的损失。' },
  { value: 24, text: '信息损失',tooltip:'信息损失是指部分或全部、永久或临时的数据损失。' },
  { value: 25, text: '时间损失' ,tooltip:'时间损失是指一项活动所延续的时间间隔。改进时间的损失指减少一项活动所花费的时间。'},
  { value: 26, text: '物质的量' ,tooltip:'物质或事物的数量是指材料、部件及子系统等的数量,它们可以被部分或全部、临时或永久地改变。'},
  { value: 27, text: '可靠性' ,tooltip:'可靠性是指系统在规定的方法及状态下完成规定功能的能力。'},
  { value: 28, text: '测量精度' ,tooltip:'测试精度是指系统特征的实测值与实际值之间的误差。减少误差将提高测试精度。'},
  { value: 29, text: '制造精度' ,tooltip:'制造精度是指系统或物体的实际性能与所需性能之间的误差。'},
  { value: 30, text: '作用于物体的有害因素',tooltip:'物体外部有害因素作用的敏感性是指物体对受外部或环境中的有害因素作用的敏感程度。' },
  { value: 31, text: '物体产生的有害因素',tooltip:'物体产生的有害因素是指有害因素将降低物体或系统的效率,或完成功能的质量。这些有害因素是由物体或系统操作的一部分而产生的。' },
  { value: 32, text: '可制造性',tooltip:'可制造性是指物体或系统制造过程中简单、方便的程度。' },
  { value: 33, text: '操作流程的方便性',tooltip:'可操作性是指要完成的操作应需要较少的操作者、较少的步骤以及使用尽可能简单的工具。一个操作的产出要尽可能多。' },
  { value: 34, text: '可维修性',tooltip:'可维修性是指对于系统可能出现失误所进行的维修要时间短、方便和简单。' },
  { value: 35, text: '适应性，通用性' ,tooltip:'适应性及多用性是指物体或系统响应外部变化的能力,或应用于不同条件下的能力。'},
  { value: 36, text: '系统的复杂性' ,tooltip:'装置的复杂性是指系统中元件数目及多样性,如果用户也是系统中的元素将增加系统的复杂性。掌握系统的难易程度是其复杂性的一种度量。监控与测试的困难程度是指如果一个系统复杂、成本高、需要较长的时间建造及使用,或部件与部件之间关系复杂,都使得系统的监控与测试困难。测试精度高,增加了测试的成本也是测试困难的一种标志。'},
  { value: 37, text: '控制和测量的复杂性' ,tooltip:'监控与测试的困难程度是指如果一个系统复杂、成本高、需要较长的时间建造及使用,或部件与部件之间关系复杂,都使得系统的监控与测试困难。测试精度高,增加了测试的成本也是测试困难的一种标志。'},
  { value: 38, text: '自动化程度' ,tooltip:'自动化程度是指自动化程度是指系统或物体在无人操作的情况下完成任务的能力。自动化程度的最低级别是完全人工操作。最高级别是机器能自动感知所需的操作、自动编程和对操作自动监控。中等级别的需要人工编程、人工观察正在进行的操作、改变正在进行的操作及重新编程。'},
  { value: 39, text: '生产率',tooltip:'生产率是指单位时间内所完成的功能或操作数。' },
]

export const principles = [
  { value: 1, text: '分割',definition:'分割原理是指将一个完整系统分割成若干个部分，并对分割后的部分进行重组，以便实现新的功能或消除有害作用。', details:[
    'A将物体分成独立的部分',
    'B使物体成为可拆卸的',
    'C增加物体的分割程度',
  ]},
  { value: 2, text: '抽取', definition:'抽取原理是指识别系统中有用或有害部分（或属性）并从系统中分离出来。应用抽取原理，需要寻求部分或属性的具体特征，以便将其轻松抽取出来。',details:[
    'A从物体中抽出产生负面影响的部分或属性',
	 'B从物体中抽出必要的部分或属性',
  ]},
  { value: 3, text: '局部质量', definition:'局部质量原理是指系统的特殊部分应具有相应的功能或条件，使之能够更好的适应环境或满足特定要求，从而使系统各部分均实现各自功能或处于最佳工作状态。',details:[
    'A从物体或外部介质（外部作用）的一致结构过渡到不一致结构',
    'B使物体的不同部分具有不同的功能',
    'C物体的每一部分均应具备最适于它工作的条件',
  ]},
  { value: 4, text: '非对称', definition:'非对称原理是指技术系统从“对称性”向“非对称性”进行变换，通过改变系统形态来优化系统。此原理可用于改变系统平衡、减少材料用量、降低系统重量、确保正确装配、检测及定位零件等。另外，通过增加非对称程度，可提升产品的易用性和可识别性。',details:[
    'A物体的对称形式转为不对称形式',
    'B如果物体是不对称的，则加强它的不对称程度',
  ]},
  { value: 5, text: '合并', definition:'合并原理，亦称组合原理，是指将两个或多个相邻的操作或部分进行合并，在多种功能、特性或部分之间建立联系，以便产生一种新的或想要的结果。合并既可以是空间上的，也可以是时间上的：在空间上进行合并，可看作系统集成或者功能集成，从而提高系统的整体性和便利性；在时间上将相同的或相关的操作进行合并，实现可序化操作，减低操作衔接成本，从而增加系统的易用性和健壮性。',details:[
    'A把相同的物体或完成类似操作的物体联合起来',
    'B把时间上相同或类似的操作联合起来',
  ]},
  { value: 6, text: '多用性', definition:'多功能原理是指将不同的功能进行合并，使系统具有多种功能，从而消除这些功能在其他系统中存在的必要性，从而增加系统的多用性。系统具有多种功能，可增加系统价值，使系统更具竞争力；将多种相关功能组合在一个系统，降低整体成本，且便于使用。特别提醒，在使用多功能原理时，需要合理、有效的整合功能，特别要重视结构设计。',details:[
    'A每一个物件、物体具有多项功能以取代其余部件',
  ]},
  { value: 7, text: '嵌套', definition:'嵌套原理是指设法使两个及以上系统彼此配合或放入。此原理可有效减少系统的体积和重量，且使物体便于携带。在使用嵌套原理时，可尝试从不同角度来考虑嵌套：水平，垂直，旋转和包容等。',details:[
    'A一个物体位于另一物体之内，而后者又位于第三个物体之内等',
    'B一个物体通过另一个物体的空腔',
  ]},
  { value: 8, text: '重量补偿', definition:'重量补偿原理是指将系统与具有上升力的另一系统结合以抵消其重量，或将系统与介质相互作用以抵消其重量。此原理充分利用空气、重力、流体等进行举升或补偿，从而抵消现有系统或外部环境中的不利作用（如力或重量）。',details:[
    'A将物体与具有上升力的另一物体结合以抵消起重量',
    'B将物体与介质（最好是气动力和液动力）相互作用以抵消重量',
  ]},
  { value: 9, text: '预先反作用', definition:'预先反作用原理是指预先判断系统可能出现的问题，并设法消除、控制或防止潜在问题的出现。此原理是为了减少系统作用过程中所带来的负面作用，重点强调的反作用，一般情况下需要引入反作用装置来实现。',details:[
    'A事先施加反作用力，用来消除不利影响',
    'B如果一个物体处于受拉伸状态，预先施加压力',
  ]},
  { value: 10, text: '预先作用', definition:'预先作用原理是指在实施某个作用之前，预先执行该作用的部分或全部。此原理是为了使系统作用的发挥更加顺利，在系统作用之前实施，一般的情况下不会改变系统的作用，也不会为系统增加额外的装置。此原理对系统进行预处理（整体或局部），使其变得更加易用，包括缩短系统功能完成时间，简化过程中的操作等。',details:[
    'A对某个物体, 在实际需要发生变化之前, 提前完成该物体全部或部分所必须实现的变化或调整',
    'B预先将物体安防妥当，使它们能在现场和最方便地点立即完成所起的作用',
  ]},
  { value: 11, text: '事先防范', definition:'事先防范原理是指通过预先准备好的应急措施（备用系统、矫正措施等）来补偿对象较低的可靠性。此原理是在系统作用发生意外（主要是可靠性带来的问题）的情况下的一种应急处理措施，在系统正常的作用过程中一般不会起作用。使用该原理时，必须承认没有任何系统是完全可靠的，特别是复杂系统。',details:[
    'A以事先准备好的应急手段补偿物体的低可靠性',
  ]},
  { value: 12, text: '等势', definition:'等势原理是指在系统及环境的所有点或方面建立均匀位势，从而保证系统以最低的能量消耗来实施作用。在势场内应避免位置的改变，如在重力场中改变系统的工作状态以减少系统升起或下降的需要。通过充分利用环境、结构和系统所提供的资源，以最低的附加能量消耗来有效地消除不等势。利用等势原理可以降低劳动者的劳动强度，工作起来更加方便。',details:[
    'A改变工作状态而不必升高或降低物品',
  ]},
  { value: 13, text: '反向作用', definition:'反向作用原理是指通过空间上将对象翻转过来（上下、左右、前后或内外翻转），在时间上将顺序颠倒过来，在逻辑关系上将原因与结果反过来，从而利用不同（或相反）的方法来实现相同的目的。此原理采用逆向思维，若事物以一种特殊方式制造或执行，则设法一种“相反”方式制造或执行，以避免固有的问题及缺陷。',details:[
    'A不用常规的解决方法，而是反其道而行之',
    'B使物体或外部介质的活动部分便成为不动的，而使不动的成为可动的',
    'C将物体运动部分倾斜',
  ]},
  { value: 14, text: '曲面化', definition:'曲面化原理是指利用曲线或曲面代替原有的直线或平面特征。此原理不仅可以指导结构设计，还可以促使线性关系向非线性转变。面临系统问题时，可以在系统中寻找线性关系，也可尝试非线性。',details:[
    'A从直线部分过渡到曲线部分，从平面过渡到球面，从正六面体或平行六面体过渡到球',
	'B利用滚筒、球体、螺旋等结构',
	'C利用离心力，以回转运动代替直线运动',
  ]},
  { value: 15, text: '动态化', definition:'动态化原理是指使构成整体的各个组成部分处于动态，及各个部分是可调整的、活动的或可互换的，以便使其在工作过程中的每个动作都处于最佳状态。此原理应用的关键在于尝试让系统的某些几何结构成为柔性的、可自适应的；相同的部分执行多种功能；某些特征成为柔性的；使系统可兼容于不同的应用或环境。',details:[
    'A物体（或外部介质）的特性的变化应当在每一工作阶段都是最佳的',
    'B将物体分成彼此相对移动的几个部分',
    'C使不动的物体成为动的',
  ]},
  { value: 16, text: '局部作用或过量', definition:'不足或过度原理是指如果难于取得百分之百所要求的功效，则应当取得略小或略大的功效。当系统不能获得最佳状态时，先从容易掌握的情况或最容易获得的东西入手，尝试在“多于”和“少于”之间过渡；尝试在“更多”和“更少”之间进行调整等。此原理可较大程度地简化问题难度。',details:[
    'A如果难于取得百分之百的效果，则应当部分达到或超越理想效果。这样可以把问题大大简化',
  ]},
  { value: 17, text: '空间维数变化', definition:'空间维数变化原理是指通过改变系统的维度来进行创新的方法。通过改变系统的维度，来改善空间的使用效率。',details:[
    'A如果物体作线性运动（或分布）有困难，则使物体在二维空间（即平面）上移动。相应的，在一个平面上的运动或分布可以过渡到三维空间',
    'B利用多层结构替代单层结构',
    'C将物体倾斜或侧置',
    'D利用指定面的反面',
	'E利用投向相邻面或反面的光流',
  ]},
  { value: 18, text: '机械振动', definition:'机械振动原理是指通过振动或摇动对象而使对象产生机械振动，增加振动的频率或利用共振频率。利用振动或摇动，在某个区间内产生种规则的、周期性的变化。',details:[
    'A使物体振动',
    'B如果已在振动，则提高它的振动频率',
    'C利用共振频率',
    'D用压电振动器替代机械振动器',
  ]},
  { value: 19, text: '周期性动作', definition:'周期性作用原理是指通过有节奏的行为（操作方式）、振幅和频率的变化，以及利用脉冲间隔，以实现周期性作用。',details:[
    'A从连续作用过渡到周期作用（脉冲）',
    'B如果作用已经是周期的，则改变周期性',
    'C利用脉冲的间歇完成其他作用',
  ]},
  { value: 20, text: '有效作用的连续性', definition:'有效作用的连续性原理是指产生连续流与（或）消除所有空闲及间歇性动作，以提高效率。',details:[
    'A连续工作（物体的所有部分均应一直满负荷工作）',
    'B消除空转和间歇运转',
	'C将重复运动改为转动',
  ]},
  { value: 21, text: '减少有害作用的时间', definition:'减少有害作用时间原理是指高速跃过某过程或其个别阶段（如有害的或危险的）。评价在一个动作执行期间出现有害（或危险）功能，将危险或有害的流程或步骤在高速下进行。',details:[
    'A快速执行一个危险或有害的作业。高速跃过某过程或其个别阶段（如有害的或危险的）',
  ]},
  { value: 22, text: '变害为利', definition:'变害为利原理是指系统中有害因素已经存在，设法用其为系统增加有益的价值。',details:[
    'A利用有害因素（特别是介质的有害作用）获得有益的效果',
    'B通过组合几个有害因素来消除有害因素',
    'C将有害因素加强到不再有害的程度',
  ]},
  { value: 23, text: '反馈', definition:'反馈原理是指将系统的输出作为输入返回到系统中，以便增强对输出的控制。系统中任何信息的改变，均可被用来执行校正系统的行为。将任何有用或有害的改变均视为一种反馈信息源，若反馈已被运用，则尝试改变反馈信息。',details:[
    'A进行反向联系',
    'B如果已有反向联系，则改变它',
  ]},
  { value: 24, text: '中介物', definition:'借助中介物原理是指利用某种可轻松去除的中间载体、阻挡物或过程，在不相容的功能之间经调解或协调而建立的一种临时链接。中介物可以在不匹配系统之间充当链接，也可以是有害功能之间的中间阻挡物。',details:[
    'A利用中介物质传递某一物体或中间过程',
    'B使用废弃资源、能量或物质',
  ]},
  { value: 25, text: '自服务', definition:'自服务原理是指系统能够自我服务，实现辅助、维修功能或者充分利用系统废弃的资源、能量或物质等。自服务强调的是让系统尽可能减少对环境或其它系统的依赖。利用系统本身废弃的资源、能量或物质来完成自服务是最佳选择。',details:[
    'A物体应当为自我服务，完成辅助和修理工作',
    'B利用废弃的资源、能量和物质',
  ]},
  { value: 26, text: '复制', definition:'复制原理是指通过使用成本较低的复制品或模型来替代成本过高而不能使用的对象。此原理在使用时不仅要考虑物体模型，还要考虑计算机模型、数学模型或其他能够满足要求的模拟技术。',details:[
    'A用简单而便宜的复制品代替难以得到的、复杂的、昂贵的、不方便的或易损坏的物品',
    'B用光学图像替代原物',
    'C可见光仪器可有红外或紫外线仪器代替',
  ]},
  { value: 27, text: '廉价替代品', definition:'廉价替代品原理是指利用廉价、易处理或一次性的等效系统替代复杂、昂贵的系统。特别注意，可以替代的对象不局限于真实系统，也可以是信息、能量或过程。',details:[
    'A用便宜的物品替代贵重的物品，对性能稍作让步',
  ]},
  { value: 28, text: '机械系统替代', definition:'机械系统替代原理是指利用物理场（光场、电场和磁场等）或其他物理结构、物理作用和状态来代替机械的相互作用、装置、机构及系统。',details:[
    'A用光学、声学等设计原理代替力学设计原理',
    'B用电磁场同物体相互作用',
    'C由恒定转向不定场，由无结构的场转向有一定结构的场',
    'D利用铁磁颗粒组成场',
  ]},
  { value: 29, text: '气动或液压结构', definition:'气压或液压结构原理是指利用系统的可压缩性或不可压缩性的属性改善系统。',details:[
    'A用气体结构和液体结构代替物体的固体部分',
  ]},
  { value: 30, text: '柔性壳体和薄膜', definition:'柔性壳体和薄膜原理是指利用柔性的、薄的物体替代厚的坚硬的物体，或将物体隔开。如果打算将一个问题与其环境隔离，或者考虑运用薄的对象代替厚的对象，都可以考虑使用该原理。',details:[
    'A利用软壳和薄膜代替一般结构',
    'B用软壳和薄膜使物体与外部介质隔离',
  ]},
  { value: 31, text: '多孔材料', definition:'多孔材料原理是指利用孔隙结构代替实心结构。在使用空穴、气泡、毛细管等孔隙结构时，这些结构可以不包含任何实物粒子（真空），也可以充满某种有用的气体、液体或固体。',details:[
    'A把物体做成多孔的或利用附加多孔原件（覆盖、镶嵌等）',
    'B如果物体是多孔的，用相应物质填充孔',
  ]},
  { value: 32, text: '改变颜色', definition:'改变颜色原理是指通过改变颜色或一些其他的光学特性来改变对象的光学性质，以便提升系统价值或解决检测问题。视觉系统是人类在认知世界过程中使用最多的感知系统，但视觉系统具有局限性，因此通过改变物体或其环境的颜色，增强或减弱视觉特征，进而达到增强或减弱视觉系统感知的效果。当目的是区别多种系统的特征（例如促进检测、改善测量或标识位置、指示状态改变目视控制、掩盖问题等）时，都可以使用该原理。',details:[
    'A改变物体或外部介质的颜色',
    'B改变物体或外部介质的透明度',
	'C为了观察难以看到的物体或过程，利用染色添加剂',
	'D如果已采用了添加剂，则用荧光粉',
  ]},
  { value: 33, text: '同质性', definition:'同质原理是指系统及与其相互作用的系统，应该由同种材料（或者具有相似性属性的材料）制成。此原理的应用有助于系统的后期维护，减少备用材料的种类。',details:[
    'A同指定物体相互作用的物体应当用同一种或性质相近的材料制成',
  ]},
  { value: 34, text: '抛弃和再生', definition:'抛弃或再生原理是指已完成使命或已无用的系统部分应当从系统中剔除，或在工作过程中直接变化；消失的部分应当在工作过程中直接再生。',details:[
    'A已完成自己使命的或已无用部分应剔除（溶解、蒸发等）或在工作过程中直接变化',
    'B消除的部分应当在工作过程中直接再利用',
  ]},
  { value: 35, text: '物理或化学的参数变化', definition:'改变状态原理，又称物理或化学参数改变原理，是指改变某个对象或系统的属性，以便提供某种有用的功能。在使用此原理时，可以考虑改变系统或对象的任意属性（对象的物理或化学状态、密度、导电性、机械柔性、温度和几何结构等）来实现系统的新功能。',details:[
    'A改变物体的态',
    'B改变浓度或密度',
    'C改变灵活程度',
    'D改变温度或体积',
  ]},
  { value: 36, text: '相变', definition:'相变原理是指利用系统在相变过程中出现的现象，实现某种效应或使某个系统发生改变。典型的相变包括：气、液、固体彼此之间的转换以及相反过程。',details:[
    'A利用相变时发生的现象，例如体积变化、放热或吸热',
  ]},
  { value: 37, text: '热胀冷缩', definition:'热膨胀原理是指利用系统受热膨胀来产生动力，并将热能转化为机械能或者机械作用。除了热场以外，还有重力、气压、海拔高度或者光线变化等因素可以引起热胀冷缩现象。热膨胀过程会产生材料体积的变化（某个方向上长度的变化），还产生很大的推力和压力（张力和缩力），利用这种效应来实现所需要的力。但这种效应有时也会带来负面作用。在利用此原理的同时也应该考虑到这种负面作用，并提前进行预防。',details:[
    'A利用物体热胀冷缩的性质',
    'B利用热膨胀系数不同的材料',
  ]},
  { value: 38, text: '加速氧化', definition:'强氧化原理是指通过增加氧量、替换氧气等方式提高氧化水平，使氧化强度从一个级别转变到更高级别。此原理应用的目的是为了加速氧化过程。提高氧化水平的次序为：空气→富氧空气→纯氧→电离氧气→臭氧→单氧。',details:[
    'A用富氧空气代替普通空气',
    'B用纯氧代替富氧空气',
    'C用电离辐射作用于空气或氧气',
    'D用离子化的氧气',
    'E用臭氧替换离子化的氧气',
  ]},
  { value: 39, text: '惰性环境', definition:'惰性环境原理是指通过去除系统及环境的氧化资源和容易与系统起反应的资源，建立一种惰性或中性环境。与强氧化原理正好相反，让系统处于一种惰性而不是活性环境。此原理应用的目的是为系统提供具有稳定而安全的化学或物理环境。制造惰性环境，可以考虑各种环境类型，包括真空、气体、液体或固体，固体惰性环境包括中性涂层，微粒或要素。',details:[
    'A用惰性介质代替普通介质',
    'B在真空中进行某些过程',
  ]},
  { value: 40, text: '复合材料', definition:'复合材料原理是指将两种或两种以上的材料形成新型材料代替均质材料。',details:[
    'A用复合材料代替单一材料',
  ]},
]

export const matrix = [
  [
    '+',
    '-',
    '15,8,29,34',
    '-',
    '29,17,38,34',
    '-',
    '29,2,40,28',
    '-',
    '2,8,15,38',
    '8,10,18,37',
    '10,36,37,40',
    '10,14,35,40',
    '1,35,19,39',
    '28,27,18,40',
    '5,34,31,35',
    '-',
    '6,29,4,38',
    '19,1,32',
    '35,12,34,31',
    '-',
    '12,36,18,31',
    '6,2,34,19',
    '5,35,3,31',
    '10,24,35',
    '10,35,20,28',
    '3,26,18,31',
    '3,11,1,27',
    '28,27,35,26',
    '28,35,26,18',
    '22,21,18,27',
    '22,35,31,39',
    '27,28,1,36',
    '35,3,2,24',
    '2,27,28,11',
    '29,5,15,8',
    '26,30,36,34',
    '28,29,26,32',
    '26,35 18,19',
    '35,3,24,37',
  ],
  [
    '-',
    '+',
    '-',
    '10,1,29,35',
    '-',
    '35,30,13,2',
    '-',
    '5,35,14,2',
    '-',
    '8,10,19,35',
    '13,29,10,18',
    '13,10,29,14',
    '26,39,1,40',
    '28,2,10,27',
    '-',
    '2,27,19,6',
    '28,19,32,22',
    '35,19,32',
    '-',
    '18,19,28,1',
    '15,19,18,22',
    '18,19,28,15',
    '5,8,13,30',
    '10,15,35',
    '10,20,35,26',
    '19,6,18,26',
    '10,28,8,3',
    '18,26,28',
    '10,1,35,17',
    '2,19,22,37',
    '35,22,1,39',
    '28,1,9',
    '6,13,1,32',
    '2,27,28,11',
    '19,15,29',
    '1,10,26,39',
    '25,28,17,15',
    '2,26,35',
    '1,28,15,35',
  ],
  [
    '8,15,29,34',
    '-',
    '+',
    '-',
    '15,17,4',
    '-',
    '7,17,4,35',
    '-',
    '13,4,8',
    '17,10,4',
    '1,8,35',
    '1,8,10,29',
    '1,8,15,34',
    '8,35,29,34',
    19,
    '-',
    '10,15,19',
    32,
    '8,35,24',
    '-',
    '1,35',
    '7,2,35,39',
    '4,29,23,10',
    '1,24',
    '15,2,29',
    '29,35',
    '10,14,29,40',
    '28,32,4',
    '10,28,29,37',
    '1,15,17,24',
    '17,15',
    '1,29,17',
    '15,29,35,4',
    '1,28,10',
    '14,15,1,16',
    '1,19,26,24',
    '35,1,26,24',
    '17,24,26,16',
    '14,4,28,29',
  ],
  [
    '-',
    '35,28,40,29',
    '-',
    '+',
    '-',
    '17,7,10,40',
    '-',
    '35,8,2,14',
    '-',
    '28,10',
    '1,14,35',
    '13,14,15,7',
    '39,37,35',
    '15,14,28,26',
    '-',
    '1,40,35',
    '3,35,38,18',
    '3,25',
    '-',
    '-',
    '12,8',
    '6,28',
    '10,28,24,35',
    '24,26,',
    '30,29,14',
    '-',
    '15,29,28',
    '32,28,3',
    '2,32,10',
    '1,18',
    '-',
    '15,17,27',
    '2,25',
    3,
    '1,35',
    '1,26',
    26,
    '-',
    '30,14,7,26',
  ],
  [
    '2,17,29,4',
    '-',
    '14,15,18,4',
    '-',
    '+',
    '-',
    '7,14,17,4',
    '-',
    '29,30,4,34',
    '19,30,35,2',
    '10,15,36,28',
    '5,34,29,4',
    '11,2,13,39',
    '3,15,40,14',
    '6,3',
    '-',
    '2,15,16',
    '15,32,19,13',
    '19,32',
    '-',
    '19,10,32,18',
    '15,17,30,26',
    '10,35,2,39',
    '30,26',
    '26,4',
    '29,30,6,13',
    '29,9',
    '26,28,32,3',
    '2,32',
    '22,33,28,1',
    '17,2,18,39',
    '13,1,26,24',
    '15,17,13,16',
    '15,13,10,1',
    '15,30',
    '14,1,13',
    '2,36,26,18',
    '14,30,28,23',
    '10,26,34,2',
  ],
  [
    '-',
    '30,2,14,18',
    '-',
    '26,7,9,39',
    '-',
    '+',
    '-',
    '-',
    '-',
    '1,18,35,36',
    '10,15,36,37',
    '-',
    '2,38',
    40,
    '-',
    '2,10,19,30',
    '35,39,38',
    '',
    '-',
    '-',
    '17,32',
    '17,7,30',
    '10,14,18,39',
    '30,16',
    '10,35,4,18',
    '2,18,40,4',
    '32,35,40,4',
    '26,28,32,3',
    '2,29,18,36',
    '27,2,39,35',
    '22,1,40',
    '40,16',
    '16,4',
    16,
    '15,16',
    '1,18,36',
    '2,35,30,18',
    23,
    '10,15,17,7',
  ],
  [
    '2,26,29,40',
    '-',
    '1,7,35,4',
    '-',
    '1,7,4,17',
    '-',
    '+',
    '-',
    '29,4,38,34',
    '15,35,36,37',
    '6,35,36,37',
    '1,15,29,4',
    '28,10,1,39',
    '9,14,15,7',
    '6,35,4',
    '-',
    '34,39,10,18',
    '10,13,2',
    35,
    '-',
    '35,6,13,18',
    '7,15,13,16',
    '36,39,34,10',
    '2,22',
    '2,6,34,10',
    '29,30,7',
    '14,1,40,11',
    '25,26,28',
    '25,28,2,16',
    '22,21,27,35',
    '17,2,40,1',
    '29,1,40',
    '15,13,30,12',
    10,
    '15,29',
    '26,1',
    '29,26,4',
    '35,34,16,24',
    '10,6,2,34',
  ],
  [
    '-',
    '35,10,19,14',
    '19,14',
    '35,8,2,14',
    '-',
    '-',
    '-',
    '+',
    '-',
    '2,18,37',
    '24,35',
    '7,2,35',
    '34,28,35,40',
    '9,14,17,15',
    '-',
    '35,34,38',
    '35,6,4',
    '-',
    '-',
    '-',
    '30,6',
    '-',
    '10,39,35,34',
    '-',
    '35,16,32 18',
    '35,3',
    '2,35,16',
    '-',
    '35,10,25',
    '34,39,19,27',
    '30,18,35,4',
    35,
    '-',
    1,
    '-',
    '1,31',
    '2,17,26',
    '-',
    '35,37,10,2',
  ],
  [
    '2,28,13,38',
    '-',
    '13,14,8',
    '-',
    '29,30,34',
    '-',
    '7,29,34',
    '-',
    '+',
    '13,28,15,19',
    '6,18,38,40',
    '35,15,18,34',
    '28,33,1,18',
    '8,3,26,14',
    '3,19,35,5',
    '-',
    '28,30,36,2',
    '10,13,19',
    '8,15,35,38',
    '-',
    '19,35,38,2',
    '14,20,19,35',
    '10,13,28,38',
    '13,26',
    '',
    '10,19,29,38',
    '11,35,27,28',
    '28,32,1,24',
    '10,28,32,25',
    '1,28,35,23',
    '2,24,35,21',
    '35,13,8,1',
    '32,28,13,12',
    '34,2,28,27',
    '15,10,26',
    '10,28,4,34',
    '3,34,27,16',
    '10,18',
    '-',
  ],
  [
    '8,1,37,18',
    '18,13,1,28',
    '17,19,9,36',
    '28,10',
    '19,10,15',
    '1,18,36,37',
    '15,9,12,37',
    '2,36,18,37',
    '13,28,15,12',
    '+',
    '18,21,11',
    '10,35,40,34',
    '35,10,21',
    '35,10,14,27',
    '19,2',
    '-',
    '35,10,21',
    '-',
    '19,17,10',
    '1,16,36,37',
    '19,35,18,37',
    '14,15',
    '8,35,40,5',
    '-',
    '10,37,36',
    '14,29,18,36',
    '3,35,13,21',
    '35,10,23,24',
    '28,29,37,36',
    '1,35,40,18',
    '13,3,36,24',
    '15,37,18,1',
    '1,28,3,25',
    '15,1,11',
    '15,17,18,20',
    '26,35,10,18',
    '36,37,10,19',
    '2,35',
    '3,28,35,37',
  ],
  [
    '10,36,37,40',
    '13,29,10,18',
    '35,10,36',
    '35,1,14,16',
    '10,15,36,28',
    '10,15,36,37',
    '6,35,10',
    '35,24',
    '6,35,36',
    '36,35,21',
    '+',
    '35,4,15,10',
    '35,33,2,40',
    '9,18,3,40',
    '19,3,27',
    '-',
    '35,39,19,2',
    '-',
    '14,24,10,37',
    '',
    '10,35,14',
    '2,36,25',
    '10,36,3,37',
    '-',
    '37,36,4',
    '10,14,36',
    '10,13,19,35',
    '6,28,25',
    '3,35',
    '22,2,37',
    '2,33,27,18',
    '1,35,16',
    11,
    2,
    35,
    '19,1,35',
    '2,36,37',
    '35,24',
    '10,14,35,37',
  ],
  [
    '8,10,29,40',
    '15,10,26,3',
    '29,34,5,4',
    '13,14,10,7',
    '5,34,4,10',
    '-',
    '14,4,15,22',
    '7,2,35',
    '35,15,34,18',
    '35,10,37,40',
    '34,15,10,14',
    '+',
    '33,1,18,4',
    '30,14,10,40',
    '14,26,9,25',
    '-',
    '22,14,19,32',
    '13,15,32',
    '2,6,34,14',
    '',
    '4,6,2',
    14,
    '35,29,3,5',
    '-',
    '14,10,34,17',
    '36,22',
    '10,40,16',
    '28,32,1',
    '32,30,40',
    '22,1,2,35',
    '35,1',
    '1,32,17,28',
    '32,15,26',
    '2,13,1',
    '1,15,29',
    '16,29,1,28',
    '15,13,39',
    '15,1,32',
    '17,26,34,10',
  ],
  [
    '21,35,2,39',
    '26,39,1,40',
    '13,15,1,28',
    37,
    '2,11,13',
    39,
    '28,10,19,39',
    '34,28,35,40',
    '33,15,28,18',
    '10,35,21,16',
    '2,35,40',
    '22,1,18,4',
    '+',
    '17,9,15',
    '13,27,10,35',
    '39,3,35,23',
    '35,1,32',
    '32,3,27,15',
    '13,19',
    '27,4,29,18',
    '32,35,27,31',
    '14,2,39,6',
    '2,14,30,40',
    '-',
    '35,27',
    '15,32,35',
    '-',
    13,
    18,
    '35,24,18,30',
    '35,40,27,39',
    '35,19',
    '32,35,30',
    '2,35,10,16',
    '35,30,34,2',
    '2,35,22,26',
    '35,22,39,23',
    '1,8,35',
    '23,35,40,3',
  ],
  [
    '1,8,40,15',
    '40,26,27,1',
    '1,15,8,35',
    '15,14,28,26',
    '3,34,40,29',
    '9,40,28',
    '10,15,14,7',
    '9,14,17,15',
    '8,13,26,14',
    '10,18,3,14',
    '10,3,18,40',
    '10,30,35,40',
    '13,17,35',
    '+',
    '27,3,26',
    '-',
    '30,10,40',
    '35,19',
    '19,35,10',
    35,
    '10,26,35,28',
    35,
    '35,28,31,40',
    '-',
    '29,3,28,10',
    '29,10,27',
    '11,3',
    '3,27,16',
    '3,27',
    '18,35,37,1',
    '15,35,22,2',
    '11,3,10,32',
    '32,40,28,2',
    '27,11,3',
    '15,3,32',
    '2,13,25,28',
    '27,3,15,40',
    15,
    '29,35,10,14',
  ],
  [
    '19,5,34,31',
    '-',
    '2,19,9',
    '-',
    '3,17,19',
    '-',
    '10,2,19,30',
    '-',
    '3,35,5',
    '19,2,16',
    '19,3,27',
    '14,26,28,25',
    '13,3,35',
    '27,3,10',
    '+',
    '-',
    '19,35,39',
    '2,19,4,35',
    '28,6,35,18',
    '-',
    '19,10,35,38',
    '-',
    '28,27,3,18',
    10,
    '20,10,28,18 ',
    '3,35,10,40',
    '11,2,13',
    3,
    '3,27,16,40',
    '22,15,33,28',
    '21,39,16,22 ',
    '27,1,4',
    '12,27',
    '29,10,27',
    '1,35,13',
    '10,4,29,15',
    '19,29,39,35',
    '6,10',
    '35,17,14,19',
  ],
  [
    '-',
    '6,27,19,16',
    '-',
    '1,40,35',
    '-',
    '-',
    '-',
    '35,34,38',
    '-',
    '-',
    '-',
    '-',
    '39,3,35,23',
    '-',
    '-',
    '+',
    '19,18,36,40',
    '-',
    '-',
    '-',
    16,
    '-',
    '27,16,18,38',
    10,
    '28,20,10,16',
    '3,35,31',
    '34,27,6,40',
    '10,26,24',
    '-',
    '17,1,40,33',
    22,
    '35,10',
    1,
    1,
    2,
    '-',
    '25,34,6,35',
    1,
    '20,10,16,38',
  ],
  [
    '36,22,6,38',
    '22,35,32',
    '15,19,9',
    '15,19,9',
    '3,35,39,18',
    '35,38',
    '34,39,40,18',
    '35,6,4',
    '2,28,36,30',
    '35,10,3,21',
    '35,39,19,2',
    '14,22,19,32',
    '1,35,32',
    '10,30,22,40',
    '19,13,39',
    '19,18,36,40',
    '+',
    '32,30,21,16',
    '19,15,3,17',
    '-',
    '2,14,17,25',
    '21,17,35,38',
    '21,36,29,31',
    '-',
    '35,28,21,18',
    '3,17,30,39',
    '19,35,3,10',
    '32,19,24',
    24,
    '22,33,35,2',
    '22,35,2,24',
    '26,27',
    '26,27',
    '4,10,16',
    '2,18,27',
    '2,17,16',
    '3,27,35,31',
    '26,2,19,16',
    '15,28,35',
  ],
  [
    '19,1,32',
    '2,35,32',
    '19,32,16',
    '',
    '19,32,26',
    '-',
    '2,13,10',
    '-',
    '10,13,19',
    '26,19,6',
    '-',
    '32,30',
    '32,3,27',
    '35,19',
    '2,19,6',
    '-',
    '32,35,19',
    '+',
    '32,1,19',
    '32,35,1,15',
    32,
    '13,16,1,6',
    '13,1',
    '1,6',
    '19,1,26,17',
    '1,19',
    '-',
    '11,15,32',
    '3,32',
    '15,19',
    '35,19,32,39',
    '19,35,28,26',
    '28,26,19',
    '15,17,13,16',
    '15,1,19',
    '6,32,13',
    '32,15',
    '2,26,10',
    '2,25,16',
  ],
  [
    '12,18,28,31',
    '-',
    '12,28',
    '-',
    '15,19,25',
    '-',
    '35,13,18',
    '-',
    '8,15,35',
    '16,26,21,2',
    '23,14,25',
    '12,2,29',
    '19,13,17,24',
    '5,19,9,35',
    '28,35,6,18',
    '-',
    '19,24,3,14',
    '2,15,19',
    '+',
    '-',
    '6,19,37,18',
    '12,22,15,24',
    '35,24,18,5',
    '-',
    '35,38,19,18',
    '34,23,16,18',
    '19,21,11,27',
    '3,1,32',
    '-',
    '1,35,6,27',
    '2,35,6',
    '28,26,30',
    '19,35',
    '1,15,17,28',
    '15,17,13,16',
    '2,29,27,28',
    '35,38',
    '32,2',
    '12,28,35',
  ],
  [
    '-',
    '19,9,6,27',
    '-',
    '-',
    '-',
    '-',
    '-',
    '-',
    '-',
    '36,37',
    '-',
    '-',
    '27,4,29,18',
    35,
    '-',
    '-',
    '-',
    '19,2,35,32',
    '-',
    '+',
    '-',
    '-',
    '28,27,18,31',
    '-',
    '-',
    '3,35,31',
    '10,36,23',
    '-',
    '-',
    '10,2,22,37',
    '19,22,18',
    '1,4',
    '-',
    '-',
    '-',
    '-',
    '19,35,16,25',
    '-',
    '1,6',
  ],
  [
    '8,36,38,31',
    '19,26,17,27',
    '1,10,35,37',
    '-',
    '19,38',
    '17,32,13,38',
    '35,6,38',
    '30,6,25',
    '15,35,2',
    '26,2,36,35',
    '22,10,35',
    '29,14,2,40',
    '35,32,15,31',
    '26,10,28',
    '19,35,10,38',
    16,
    '2,14,17,25',
    '16,6,19',
    '16,6,19,37',
    '-',
    '+',
    '10,35,38',
    '28,27,18,38',
    '10,19',
    '35,20,10,6',
    '4,34,19',
    '19,24,26,31',
    '32,15,2',
    '32,2',
    '19,22,31,2',
    '2,35,18',
    '26,10,34',
    '26,35,10',
    '35,2,10,34',
    '19,17,34',
    '20,19,30,34',
    '19,35,16',
    '28,2,17',
    '28,35,34',
  ],
  [
    '15,6,19,28',
    '19,6,18,9',
    '7,2,6,13',
    '6,38,7',
    '15,26,17,30',
    '17,7,30,18',
    '7,18,23',
    7,
    '16,35,38',
    '36,38',
    '-',
    '-',
    '14,2,39,6',
    26,
    '-',
    '-',
    '19,38,7',
    '1,13,32,15',
    '-',
    '-',
    '3,38',
    '+',
    '35,27,2,37',
    '19,10',
    '10,18,32,7',
    '7,18,25',
    '11,10,35',
    32,
    '-',
    '21,22,35,2',
    '21,35,2,22',
    '-',
    '35,32,1',
    '2,19',
    '-',
    '7,23',
    '35,3,15,23',
    2,
    '28,10,29,35',
  ],
  [
    '35,6,23,40',
    '35,6,22,32',
    '14,29,10,39',
    '10,28,24',
    '35,2,10,31',
    '10,18,39,31',
    '1,29,30,36',
    '3,39,18,31',
    '10,13,28,38',
    '14,15,18,40',
    '3,36,37,10',
    '29,35,3,5',
    '2,14,30,40',
    '35,28,31,40',
    '28,27,3,18',
    '27,16,18,38',
    '21,36,39,31',
    '1,6,13',
    '35,18,24,5',
    '28,27,12,31',
    '28,27,18,38',
    '35,27,2,31',
    '+',
    '-',
    '15,18,35,10',
    '6,3,10,24',
    '10,29,39,35',
    '16,34,31,28',
    '35,10,24,31',
    '33,22,30,40',
    '10,1,34,29',
    '15,34,33',
    '32,28,2,24',
    '2,35,34,27',
    '15,10,2',
    '35,10,28,24',
    '35,18,10,13',
    '35,10,18',
    '28,35,10,23',
  ],
  [
    '10,24,35',
    '10,35,5',
    '1,26',
    26,
    '30,26',
    '30,16',
    '-',
    '2,22',
    '26,32',
    '-',
    '-',
    '-',
    '-',
    '-',
    10,
    10,
    '-',
    19,
    '-',
    '-',
    '10,19',
    '19,10',
    '-',
    '+',
    '24,26,28,32',
    '24,28,35',
    '10,28,23',
    '-',
    '-',
    '22,10,1',
    '10,21,22',
    32,
    '27,22',
    '-',
    '-',
    '-',
    '35,33',
    35,
    '13,23,15',
  ],
  [
    '10,20,37,35',
    '10,20,26,5',
    '15,2,29',
    '30,24,14,5',
    '26,4,5,16',
    '10,35,17,4',
    '2,5,34,10',
    '35,16,32,18',
    '-',
    '10,37,36,5',
    '37,36,4',
    '4,10,34,17',
    '35,3,22,5',
    '29,3,28,18',
    '20,10,28,18',
    '28,20,10,16',
    '35,29,21,18',
    '1,19,26,17',
    '35,38,19,18',
    1,
    '35,20,10,6',
    '10,5,18,32',
    '35,18,10,39',
    '24,26,28,32',
    '+',
    '35,38,18,16',
    '10,30,4',
    '24,34,28,32',
    '24,26,28,18',
    '35,18,34',
    '35,22,18,39',
    '35,28,34,4',
    '4,28,10,34',
    '32,1,10',
    '35,28',
    '6,29',
    '18,28,32,10',
    '24,28,35,30',
    '-',
  ],
  [
    '35,6,18,31',
    '27,26,18,35',
    '29,14,35,18',
    '-',
    '15,14,29',
    '2,18,40,4',
    '15,20,29',
    '-',
    '35,29,34,28',
    '35,14,3',
    '10,36,14,3',
    '35,14',
    '15,2,17,40',
    '14,35,34,10',
    '3,35,10,40',
    '3,35,31',
    '3,17,39',
    '-',
    '34,29,16,18',
    '3,35,31',
    35,
    '7,18,25',
    '6,3,10,24',
    '24,28,35',
    '35,38,18,16',
    '+',
    '18,3,28,40',
    '13,2,28',
    '33,30',
    '35,33,29,31',
    '3,35,40,39',
    '29,1,35,27',
    '35,29,10,25',
    '2,32,10,25',
    '15,3,29',
    '3,13,27,10',
    '3,27,29,18',
    '8,35',
    '13,29,3,27',
  ],
  [
    '3,8,10,40',
    '3,10,8,28',
    '15,9,14,4',
    '15,29,28,11',
    '17,10,14,16',
    '32,35,40,4',
    '3,10,14,24',
    '2,35,24',
    '21,35,11,28',
    '8,28,10,3',
    '10,24,35,19',
    '35,1,16,11',
    '-',
    '11,28',
    '2,35,3,25',
    '34,27,6,40',
    '3,35,10',
    '11,32,13',
    '21,11,27,19',
    '36,23',
    '21,11,26,31',
    '10,11,35',
    '10,35,29,39',
    '10,28',
    '10,30,4',
    '21,28,40,3',
    '+',
    '32,3,11,23',
    '11,32,1',
    '27,35,2,40',
    '35,2,40,26',
    '-',
    '27,17,40',
    '1,11',
    '13,35,8,24',
    '13,35,1',
    '27,40,28',
    '11,13,27',
    '1,35,29,38',
  ],
  [
    '32,35,26,28',
    '28,35,25,26',
    '28,26,5,16',
    '32,28,3,16',
    '26,28,32,3',
    '26,28,32,3',
    '32,13,6',
    '-',
    '28,13,32,24',
    '32,2',
    '6,28,32',
    '6,28,32',
    '32,35,13',
    '28,6,32',
    '28,6,32',
    '10,26,24',
    '6,19,28,24',
    '6,1, 32',
    '3,6,32',
    '-',
    '3,6,32',
    '26,32,27',
    '10,16,31,28',
    '-',
    '24,34,28,32',
    '2,6,32',
    '5,11,1,23',
    '+',
    '-',
    '28,24,22,26',
    '3,33,39,10',
    '6,35,25,18',
    '1,13,17,34',
    '1,32,13,11',
    '13,35,2',
    '27,35,10,34',
    '26,24,32,28',
    '28,2,10,34',
    '10,34,28,32',
  ],
  [
    '28,32,13,18',
    '28,35,27,9',
    '10,28,29,37',
    '2,32,10',
    '28,33,29,32',
    '2,29,18,36',
    '32,28,2',
    '25,10,35',
    '10,28,32',
    '28,19,34,36',
    '3,35',
    '32,30,40',
    '30,18',
    '3,27',
    '3,27,40',
    '-',
    '19,26',
    '3,32',
    '32,2',
    '-',
    '32,2',
    '13,32,2',
    '35,31,10,24',
    '-',
    '32,26,28,18',
    '32,30',
    '11,32,1',
    '-',
    '+',
    '26,28,10,36',
    '4,17,34,26',
    '-',
    '1,32,35,23',
    '25,10',
    '-',
    '26,2,18',
    '-',
    '26,28,18,23',
    '10,18,32,39',
  ],
  [
    '22,21,27,39',
    '2,22,13,24',
    '17,1,39,4',
    '1,18',
    '22,1,33,28',
    '27,2,39,35',
    '22,23,37,35',
    '34,39,19,27',
    '21,22,35,28',
    '13,35,39,18',
    '22,2,37',
    '22,1,3,35',
    '35,24,30,18',
    '18,35,37,1',
    '22,15,33,28',
    '17,1,40,33',
    '22,33,35,2',
    '1,19,32,13',
    '1,24, 6,27',
    '10,2,22,37',
    '19,22,31,2',
    '21,22,35,2',
    '33,22,19,40',
    '22,10,2',
    '35,18,34',
    '35,33,29,31',
    '27,24,2,40',
    '28,33,23,26',
    '26,28,10,18',
    '+',
    '-',
    '24,35,2',
    '2,25,28,39',
    '35,10,2',
    '35,11,22,31',
    '22,19,29,40',
    '22,19,29,40',
    '33,3,34',
    '22,35,13,24',
  ],
  [
    '19,22,15,39',
    '35,22,1,39',
    '17,15,16,22',
    '-',
    '17,2,18,39',
    '22,1,40',
    '17,2,40',
    '30,18,35,4',
    '35,28,3,23',
    '35,28,1,40',
    '2,33,27,18',
    '35,1',
    '35,40,27,39',
    '15,35,22,2',
    '15,22,33, 31',
    '21,39,16,22',
    '22,35,2,24',
    '19,24,39,32',
    '2,35,6',
    '19,22,18',
    '2,35,18',
    '21,35,2,22',
    '10,1,34',
    '10,21,29',
    '1,22',
    '3,24,39,1',
    '24,2,40,39',
    '3,33,26',
    '4,17,34,26',
    '-',
    '+',
    '-',
    '-',
    '-',
    '-',
    '19,1,31',
    '2,21,27,1',
    2,
    '22,35,18,39',
  ],
  [
    '28,29,15,16',
    '1,27,36,13',
    '1,29,13,17',
    '15,17,27',
    '13,1,26,12',
    '16,40',
    '13,29,1,40',
    35,
    '35,13,8,1',
    '35,12',
    '35,19,1,37',
    '1,28,13,27',
    '11,13,1',
    '1,3,10,32',
    '27,1,4',
    '35,16',
    '27,26,18',
    '28,24,27,1',
    '28,26,27,1',
    '1,4',
    '27,1,12,24',
    '19,35',
    '15,34,33',
    '32,24,18,16',
    '35,28,34,4',
    '35,23,1,24',
    '-',
    '1,35,12,18',
    '-',
    '24,2',
    '-',
    '+',
    '2,5,13,16',
    '35,1,11,9',
    '2,13,15',
    '27,26,1',
    '6,28,11,1',
    '8,28,1',
    '35,1,10,28',
  ],
  [
    '25,2,13,15',
    '6,13,1,25',
    '1,17,13,12',
    '-',
    '1,17,13,16',
    '18,16,15,39',
    '1,16,35,15',
    '4,18,39,31',
    '18,13,34',
    '28,13 35',
    '2,32,12',
    '15,34,29,28',
    '32,35,30',
    '32,40,3,28',
    '29,3,8,25',
    '1,16,25',
    '26,27,13',
    '13,17,1,24',
    '1,13,24',
    '-',
    '35,34,2,10',
    '2,19,13',
    '28,32,2,24',
    '4,10,27,22',
    '4,28,10,34',
    '12,35',
    '17,27,8,40',
    '25,13,2,34',
    '1,32,35,23',
    '2,25,28,39',
    '-',
    '2,5,12',
    '+',
    '12,26,1,32',
    '15,34,1,16',
    '32,26,12,17',
    '-',
    '1,34,12,3',
    '15,1,28',
  ],
  [
    '2,27  35,11',
    '2,27,35,11',
    '1,28,10,25',
    '3,18,31',
    '15,13,32',
    '16,25',
    '25,2,35,11',
    1,
    '34,9',
    '1,11,10',
    13,
    '1,13,2,4',
    '2,35',
    '1,11,2,9',
    '11,29,28,27',
    1,
    '4,10',
    '15,1,13',
    '15,1,28,16',
    '-',
    '15,10,32,2',
    '15,1,32,19',
    '2,35,34,27',
    '-',
    '32,1,10,25',
    '2,28,10,25',
    '11,10,1,16',
    '10,2,13',
    '25,10',
    '35,10,2,16',
    '-',
    '1,35,11,10',
    '1,12,26,15',
    '+',
    '7,1,4,16',
    '35,1,13,11',
    '-',
    '34,35,7,13',
    '1,32,10',
  ],
  [
    '1,6,15,8',
    '19,15,29,16',
    '35,1,29,2',
    '1,35,16',
    '35,30,29,7',
    '15,16',
    '15,35,29',
    '-',
    '35,10,14',
    '15,17,20',
    '35,16',
    '15,37,1,8',
    '35,30,14',
    '35,3,32,6',
    '13,1,35',
    '2,16',
    '27,2,3,35',
    '6,22,26,1',
    '19,35,29,13',
    '-',
    '19,1,29',
    '18,15,1',
    '15,10,2,13',
    '-',
    '35,28',
    '3,35,15',
    '35,13,8,24',
    '35,5,1,10',
    '-',
    '35,11,32,31',
    '-',
    '1,13,31',
    '15,34,1,16',
    '1,16,7,4',
    '+',
    '15,29,37,28',
    1,
    '27,34,35',
    '35,28,6,37',
  ],
  [
    '26,30,34,36',
    '2,26,35,39',
    '1,19,26,24',
    26,
    '14,1,13,16',
    '6,36',
    '34,26,6',
    '1,16',
    '34,10,28',
    '26,16',
    '19,1,35',
    '29,13,28,15',
    '2,22,17,19',
    '2,13,28',
    '10,4,28,15',
    '-',
    '2,17,13',
    '24,17,13',
    '27,2,29,28',
    '-',
    '20,19,30,34',
    '10,35,13,2',
    '35,10,28,29',
    '-',
    '6,29',
    '13,3,27,10',
    '13,35,1',
    '2,26,10,34',
    '26,24,32',
    '22,19,29,40',
    '19,1',
    '27,26,1,13',
    '27,9,26,24',
    '1,13',
    '29,15,28,37',
    '+',
    '15,10,37,28',
    '15,1,24',
    '12,17,28',
  ],
  [
    '27,26,28,13',
    '6,13,28,1',
    '16,17,26,24',
    26,
    '2,13,18,17',
    '2,39,30,16',
    '29,1,4,16',
    '2,18,26,31',
    '3,4,16,35',
    '36,28,40,19',
    '35,36,37,32',
    '27,13,1,39',
    '11,22,39,30',
    '27,3,15,28',
    '19,29,25,39',
    '25,34,6,35',
    '3,27,35,16',
    '2,24,26',
    '35,38',
    '19,35,16',
    '19,1,16,10',
    '35,3,15,19',
    '1,18,10,24',
    '35,33,27,22',
    '18,28,32,9',
    '3,27,29,18',
    '27,40,28,8',
    '26,24,32,28',
    '-',
    '22,19,29,28',
    '2,21',
    '5,28,11,29',
    '2,5',
    '12,26',
    '1,15',
    '15,10,37,28',
    '+',
    '34,21',
    '35,18',
  ],
  [
    '28,26,18,35',
    '28,26,35,10',
    '14,13,28,17',
    23,
    '17,14,13',
    '-',
    '35,13,16',
    '-',
    '28,10',
    '2,35',
    '13,35',
    '15,32,1,13',
    '18,1',
    '25,13',
    '6,9',
    '-',
    '26,2,19',
    '8,32,19',
    '2,32,13',
    '-',
    '28,2,27',
    '23,28',
    '35,10,18,5',
    '35,33',
    '24,28,35,30',
    '35,13',
    '11,27,32',
    '28,26,10,34',
    '28,26,18,23',
    '2,33',
    2,
    '1,26,13',
    '1,12,34,3',
    '1,35,13',
    '27,4,1, 35',
    '15,24,10',
    '34,27,25',
    '+',
    '5,12,35,26',
  ],
  [
    '35,26,24,37',
    '28,27,15,3',
    '18,4,28,38',
    '30,7,14,26',
    '10,26,34,31',
    '10,35,17,7',
    '2,6,34,10',
    '35,37,10,2',
    '-',
    '28,15,10,36',
    '10,37,14',
    '14,10,34,40',
    '35,3,22,39',
    '29,28,10,18',
    '35,10,2,18',
    '20,10,16,38',
    '35,21,28,10',
    '26,17,19,1',
    '35,10,38,19',
    1,
    '35,20,10',
    '28,10,29,35',
    '28,10,35,23',
    '13,15,23',
    '-',
    '35,38',
    '1,35,10,38',
    '1,10,34,28',
    '32,1,18,10',
    '22,35,13,24',
    '35,22,18,39',
    '35,28,2,24',
    '1,28,7,19',
    '1,32,10,25',
    '1,35,28,37',
    '12,17,28,24',
    '35,18,27,2',
    '5,12,35,26',
    '+',
  ],
]
